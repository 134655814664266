<template>
  <div  class="main-container">  
    <img src="../assets/wallet-bg.jpg" class="bg">

     <div id="loading" v-if="showLoading">
      <div class="content">
 
        <v-icon name="spinner" pulse scale="3" />

      </div>
    </div>


    <div class="header">
      <div class="d-flex" style="width:50%; cursor: pointer;" @click="goToDefault"> 
        <div class="mr-3"><v-icon name="chevron-left" class="chevron-left" /></div>
        <h5>BACK</h5>
      </div>
      
    <div class="d-flex" style="align-items: end; justify-content: end; text-align: end;">
        <a href="#" class="logout" v-on:click="logout">Logout</a>
      </div>
    </div>

  
    <div class="content d-flex flex-wrap"> 
      <b-card
          :title="assetInfo.assetName"
          img-alt="Image"
          :img-src="assetInfo.icon"
          img-top
          tag="article"
          style="width: 20rem;background-color: rgba(199, 199, 199, 0.1); height:auto;"
          class="m-5 p-3 text-white text-center"
      ></b-card>
      <div class="asset-info-wrapper text-white" style="width:70rem">
        <div class="d-flex justify-content-between mb-3">
          <div>
            <h6>BALANCE</h6>
            <h5>{{ formatAmount(assetBalance) }}</h5>

            <h6 class="mt-3">TOTAL SUPPLY</h6>
            <h5>{{ assetInfo.supply }}</h5>
          </div>
          <div>
            <h6>ASSET NAME</h6>
            <h5>{{ assetInfo.assetName }}</h5>

            <h6 class="mt-3">WALLET ADDRESS</h6>
            <h5>{{ init.address.addressValue }}</h5>
          </div>
          <div>
            <h6>ASSET SYMBOL</h6>
            <h5>{{ assetInfo.symbol }}</h5>
          </div>
        </div>
        <div>
          <h6>DESCRIPTION</h6>
            <h5 v-if="assetInfo.symbol != 'JCASH'">{{ assetInfo.description }}</h5>
            <h5 v-if="assetInfo.symbol == 'JCASH'">JCash.io Asset Token</h5>
        </div>
      </div>
    </div>


    <div class="transaction-content"> 
          <div class="history-header"> 
            <span> RECENT TRANSACTIONS </span>
          </div>
          
          <div class="history-content">
            <table>
              <tr>
                <th>TIMESTAMP</th>
                <th>HASH</th>
                <th>INPUT</th>
                <th>OUTPUT</th>
                <th>AMOUNT</th> 
                <th>CONFIRMATIONS</th>
                <th>STATUS</th>
              </tr>
              <tr v-for="h in assetHistory" :key="h.hash">
                <td >{{ formatDate(h.input.timeStamp) }}</td>
                <!-- <td class="th-orange"> <div class="hash"> <a :href="'https://test-explorer-cxt.coexstar.com/#/transaction/' + h.hash" target="_blank">{{ h.hash }}</a> </div> </td> -->
                <td class="th-orange"> <div class="hash"> <a :href="'https://test-explorer-cxt.coexstar.com/#/transaction/' + h.hash" target="_blank">{{ h.hash }}</a> </div> </td>
                <td class="th-orange"> {{ h.senderWallet != null ? h.senderWallet.publicKey : "CXT Genesis Asset" }}  </td>
                <td class="th-orange">{{ h.recipient }}</td>
                <td> {{ h.symbol }} {{ formatAmount(h.amount) }} </td> 
                 <td class="th-orange">{{ h.confirmations }}</td>
                 <td v-if="h.confirmations >= 2">COMPLETED</td>
                 <td v-else>PENDING</td>
              </tr>
            
            </table>
        
          </div> 
    </div>

    <Send />
    <Deposit />
    <ExportImportFund />
    <Redeem />
    <PrivateKey />
    <ConfirmLogout />
  </div>
</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral' 
  import Send from '@/components/modal/SendMintedAsset.vue'
  import Deposit from '@/components/modal/Deposit.vue'
  import ExportImportFund from '@/components/modal/ExportImportMintedAsset.vue'
  import Redeem from '@/components/modal/Redeem.vue'
  import PrivateKey from '@/components/modal/PrivateKey.vue'
  import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
  import Pusher from 'pusher-js' // import Pusher

  export default {
    name: 'Dashboard',
    components: {
      Send,
      Deposit,
      ExportImportFund,
      Redeem,
      PrivateKey,
      ConfirmLogout,
    },
    data: function() {
      return  {
        showLoading: true, 
        assetInfo: {},
        assetHistory: {},
        assetBalance: 0
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {

      goToDefault() {
        this.$router.push('/')
      },

      
      formatAmount: function(num){

return numeral(num).format('0,0.00000000');
},

      formatDate: function(time){
        return new Date(time).toLocaleString();
      },

      getAddress: function(){
         const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/address/CXT',
          };

          this.axios(options)
            .then((response) => {
                this.init.address = response.data;
                //console.log(this.init.address)
                this.getFund();
                this.getSubwalletInfo();
              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },

      getAssetInfo() {
				const params = {
					symbol: this.$route.params.token,
					}

					const data = Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');

			const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					url: '/pub/smartcontract/details',
					data,
					};
					this.axios(options)
						.then((response) => {
							this.assetInfo = response.data
              this.getFund();
						})
						.catch((error) => {
							if(error.response.data.msgText == 'You still have pending contract.') {
								this.state = 'otp'
							}
						});
			},
      getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
             url: '/cxt-api/address/' + this.assetInfo.symbol.replace(/^\s+|\s+$/gm,'') + '/' + this.init.address.addressValue,
            //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
          };

          this.axios(options)
            .then((response) => {
               this.assetBalance = response.data.balance
               this.assetHistory = response.data.history

               this.assetHistory.sort(function(a, b) {

return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
});

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },
      getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
               this.init.profile = response.data;
 


                
              })
      },
      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => { 
            this.getProfile();
              setTimeout( () => {
                this.showLoading = false;
              },1000)
            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      logout: function(){
            this.$bvModal.show('logout-modal'); 
      },

     // subscribe () {
      //   let pusher = new Pusher('609acb09a8614556b686', { cluster: 'ap3' })
      //   pusher.subscribe('blx')
      //   pusher.bind('balance', data => {
      //     this.getFund();
      //   })
      // }
    },

    beforeMount(){
     this.checkSession();
     this.getAssetInfo();
      this.getAddress();
    },

    mounted() {
    },

     created () {
      // ...
     // this.subscribe()
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  #loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  color: #FFF;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
      background: #141414;
}
#loading .content {
  display: inline-block;
  text-align: center;
}
#loading .content img {
  display: block;
  margin-bottom: 0.5em;
}
#loading .content .icon {
  font-size: 3em;
}

h6 {
  color:#b48b41;
}

.asset-info-wrapper {
  background-color:#2D2D2D;
  padding:20px;
  border-radius:5px;
}
.fa-icon { 
    color: #FFF;
    fill: #fff;
}
  .z-index-2 {
    z-index: 2;
  }
  .transaction-content {
    padding: 0 14em;
    margin-top: 3em;

  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #333;
  }
   .hash {
    white-space: nowrap !important;
    width: 20em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  td, th {
   
    text-align: left;
    padding: 1em;
    font-size: 0.75em;
  }

  td {  
    background: #2D2D2D;
    color: #FFF;
    border-bottom: 1px solid #FFF;
    padding: 1.5em;
  
  }

  th {
     
    background: #606060;
    color: #FFF;
  }

  th:first-child { 
    border-top-left-radius: 6px;
  }
  th:last-child { 
    border-top-right-radius: 6px;
  }

  .history-content {
    background: #FFF; 
      border-radius: 6px;
        overflow-x:auto;
  }
    


  .history-container {  
      padding: 1.5em;
      border-radius: 6px;
      flex-direction: column;
      position: relative;
      background: #FFF;
      padding-top: 5em;
      color: #000;
      background-color: #b48b41;
      background-image: linear-gradient(#b48b41, #b48b41);
      width: 100%;
      margin: 0 2em;
  }
  
  .history-header {  
    color: #FFF;
    display: flex;
    justify-content: left;
    align-items: center;;
  }
.logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    margin-top: 2em;
}
 .logo img {
      width: 75px;  
    }
    
  


  .option-box {

    background: #141414;
    border: 1px solid #8c5b13;
    color: #FFF;
    border-radius: 6px; 
    min-height: 15em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3em;
    cursor: pointer;
  }
  .option-box h6 {
      font-size: 0.75em;
    }
  
 .content-icon {
      width: 75px;
    }
  .balance {
    font-size: 0.8em;
    position: relative;
    width: 30%; 
    background-image: url(../assets/wallet-bg.jpg);
    box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
-webkit-box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
-moz-box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
    padding: 1em;
    color: #FFF;
    border-radius: 6px;
    min-height: 11em;
    display: flex;
    flex-direction: column;
    justify-content: center;
        background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
    .balance .bal-bg {
          position: absolute;
        z-index: 1;
        width: 20em;
        left: 2em;
        top: 2em;
    }

    .balance .bal-bg a {
      color: #8c5b13 !important;
    }
  

  .header { 
    
    background: #141414;
    padding: 2em;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: #FFF;
  }
  .logout {
    color: #FFF;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1em;
    padding: 0 15em;
  }
  .add-btn {
    background: transparent;
    padding: 0 !important;
    margin: 0!important;
    text-decoration: none !important;
  }
  .add-btn .plus {
    color: black;
  }
  .add-btn:hover {
    background: transparent;
    color: black;
  }
  .add-btn:focus {
    background: transparent !important;
    color: black;
  }

  .btn-secondary {
      background:none !important;
      border-color:orange !important;
      color:white !important;
      border-width:2px !important;
      margin-left:5px !important;
    }

    .bloxchain {
      width:7em;
    }

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
 
  }
  /*med*/
  @media(max-width: 63.9375em) {

    
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(min-width: 768px) {
    .content {
      padding: 0 3em;
    }

    .transaction-content {
      padding: 3em;
    }

  }

</style>



