<template>
	<b-modal id="generate-key-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" v-if="ei.state != 'show-key'"/>
			</div>
			<h5>
				GENERATE WALLET ADDRESS
			</h5> 
			<div></div>

    </template>

    <div v-if="ei.state == 'pin'"> 
      <form  v-on:submit.prevent="verifyPin" class="text-center">
        <p class="text-center">Enter your PIN:</p>
        <div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
        <div class="d-flex justify-content-center">
          <div class="pin-wrapper" >
            <div>
              <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
            </div>
        
            <div>
              <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
            </div>
        
            <div>
              <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
            </div>
        
            <div>
              <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
            </div>
  
            <div>
              <input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
              </div>
        
              <div>
              <input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
              </div>
        
            </div> 
        </div>
    
        <div class="form-group ">
          <button type="submit" class="btn btn-send">SUBMIT</button>
        </div>
  
      </form>
    </div>
    <div v-if="ei.state == 'otp'"> 
      <form  v-on:submit.prevent="verifyOtp">
    
        <p class="text-center">Enter the one-time PIN you received:</p>
        <div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
        <div class="d-flex justify-content-center">
          <div class="otp-wrapper" >
          <div>
            <input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
          </div>
      
          <div>
            <input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
          </div>
      
          <div>
            <input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
          </div>
      
          <div>
            <input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
          </div>
      
          <div>
            <input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
          </div>
      
          <div>
            <input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
          </div>
          </div>
        </div>
    <div class="text-center">
      <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
      <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
        </div>
    
        <div class="form-group text-center">
          <button type="submit" class="btn btn-send">SUBMIT</button>
        </div>
      </form>
    </div>
		<div v-if="ei.state == 'generating'"> 
			<div class="text-center">
          <h4>Generating your wallet...</h4>
      </div>
		</div>
		<div v-if="ei.state == 'success'" class="text-center"> 
      <h5>You have successfully generated a new wallet
        address.</h5>
		</div>
    <div v-if="ei.state == 'show-key'"> 
      <div class="text-center d-flex justify-content-center copyWrapper">
        <input type="text" v-model="privateKey" class="form-control" id="keyInput">
        <button class="btn btn-main" style="width:20%; margin:0;" @click="copyKey">COPY</button>
      </div>
      <button class="btn btn-main" @click="nextStep">NEXT</button>
		</div>
    <form  v-if="ei.state == 'seed'">
								


      <h4 class="text-center">Wallet Seed</h4>
      <div class="text-center">
        <p>Please save these 5 codes with 7 Alpha numeric characters on paper (order is important). This seed will allow you to recover your wallet in case of computer failure.</p>

        <p>WARNING:<br>
      - Never disclose your seed. <br>
      - Never type it on a website. <br>
      - Do not store it electronically. <br>
      - You can only access this once. <br>


      <p>Your wallet generation seed are:</p>
      </div>



      <div class="seed-request-wrapper">
        <div class="seed-request" v-for="(seed, index) in seedRequest"> 
          <div class="speed-index"> {{ index+1 }} </div>
         {{ seed }} 
        </div>
      </div>

       <div class="flex  margin-top">
          <button  class="btn btn-main" type="button" v-on:click="onConfirm">  
            <span>CLOSE</span>	
          </button>
           
        </div>


    </form>
    <LeaveKey/>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
import PrivateKey from '@/components/modal/PrivateKey.vue'
import LeaveKey from '@/components/modal/ConfirmLeaveGenerateAddress.vue'
export default {
  name: 'ExportImportFund',
  components: {
	  LeaveKey
  },
  props: ["state"],
  data: function() {
      return  {
      	ei: {
      		state: 'pin'
      	},
      	showError: false,
        systemMsg: "",
        seed: {
      		one: "",
      		two: "",
      		three: "",
      		four: "",
      		five: "",
      	},
        privateKey: "",
        showDot: {
          one: true,
          two: false,
          three: false,
        },
        ifResent: true,
        timerInSeconds: 60,
        pinArr: [],
        otpArr: [],
        systemMsg: "",
        showError: false,
        seedRequest: [],
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {

   		close: function(){
   			this.$bvModal.hide('generate-key-modal'); 
   		},

       onConfirm() {
        this.$bvModal.show('leave-key-modal'); 
       },
 
       nextStep() {
         if(this.ei.state == 'show-key') {
          this.ei.state = 'seed'
          this.requestSeedPhrase();
         }
       },

       requestSeedPhrase: function() {

const options = {
  method: 'GET',
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
  url: '/cx/private/seedphrase',
};

this.axios(options)
  .then((response) => { 
    this.seedRequest =  response.data; 

    localStorage.setItem("seed",JSON.stringify(this.seedRequest));
  
        if(response.data.length > 0) {
          this.seedShown = true;
        }

        this.showLoading = false;

    }).catch((err) => {
        
      console.log(err)

      this.seedRequest = JSON.parse(localStorage.getItem("seed"));


    })
},

       goBack() {
        this.ei.state = 'seed'
       },

       copyKey() {
        let copyText = document.getElementById("keyInput");
          copyText.select();
          copyText.setSelectionRange(0, 99999); 

          document.execCommand("copy");
      },

      resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {
						this.ei.state = 'otp'
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
						this.showError = true
						this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
						},10000);
					});

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                     this.ei.state = 'generating'
                     this.generateKey();
					        this.clearError();
                    })
                    .catch((error) => {
                        // Error code goes here
						this.showError = true
            			this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
        },

		isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {
		if(this.ei.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.ei.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },

        generateKey() {
          const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: ' /cx/private/generatenew/cxt',
          };
          this.axios(options)
            .then((response) => {
						  setTimeout(()=>{
                
                 this.getWalletAddress();
              },3000);
            })
            .catch((error) => {
              this.generateKey();
					  })
          },

        getWalletAddress() {
          const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '  /cx/cryptoaddress/cxt',
          };
          this.axios(options)
            .then((response) => {
						  if(response.data.addressValue == null) {
                 setTimeout(()=>{
                  
                  this.getWalletAddress();
                },3000);
              } else {
                this.ei.state = 'success'
                setTimeout(()=>{
                
                  this.ei.state = 'show-key'
                },3000);
                 this.privateKey = response.data.addressValue
              }
              
             
            })
            .catch((error) => {
              
					  })
          },


       clearError() {
        this.systemMsg = ""
        this.showError = false
      },
   		

	},
   	mounted(){
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

   .copyWrapper {
     width:100%;
     padding:5px;
   }

   .copyWrapper input {
     width:80%;
     padding:5px;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #b48b41;
          border-bottom: 1px solid #b48b41;
        }
      }
 
    }

    .btn-send {
   background-color:orange;
   color:white;
   margin-top:20px;
   padding:5px 20px;
   height:40px;
 }

	.pin-wrapper {
      display: flex;
      width: 66%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
              padding-bottom:7.5px;
      }
    }
	}
	.otp-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
	}

	.btn-upload {
		border-color:lightgray;
		border-width:1px;
		color:gray;
		width:100%;
	}

  .seed-request-wrapper {
  	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .seed-request {

 		   width: 33.33%;
 		   display: flex;
 		       margin-top: 1em;


 		   .speed-index {
	 		       background: #DB8D20;
			    color: #FFF;
			    height: 25px;
			    width: 25px;
			    border-radius: 50%;
			        margin-right: 0.5em;
			    display: flex;
			    justify-content: center;
 		   }
    }
  }
	
</style>
