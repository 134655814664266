<template>
	<b-modal id="pin-key-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				VIEW PRIVATE KEY
			</h5> 
			<div></div>

    </template>


		<div v-if="init.viewPrivKey.state == 'pin'"> 
			<form  v-on:submit.prevent="verifyPin" class="text-center">
				<p class="text-center">Enter your PIN:</p>
				<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
				<div class="d-flex justify-content-center">
					<div class="pin-wrapper" >
						<div>
						  <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
						</div>
			  
						<div>
						  <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
						</div>
			  
						<div>
						  <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
						</div>
			  
						<div>
						  <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
						</div>

						<div>
							<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
						  </div>
				
						  <div>
							<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
						  </div>
			  
					  </div> 
				</div>
		
				<div class="form-group ">
					<button type="submit" class="btn btn-main">SUBMIT</button>
				</div>
	
			</form>
		</div>
		<div v-if="init.viewPrivKey.state == 'otp'"> 
			<form  v-on:submit.prevent="verifyOtp">
		
				<p class="text-center">Enter the one-time PIN you received:</p>
				<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
				<div class="d-flex justify-content-center">
					<div class="otp-wrapper" >
					<div>
						<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					</div>
					</div>
				</div>
        <div class="text-center">
          <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
          <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
				</div>
		
				<div class="form-group ">
					<button type="submit" class="btn btn-main">SUBMIT</button>
				</div>
			</form>
		</div>
		<PrivateKey v-if="showPrivateKey"/>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
import PrivateKey from '@/components/modal/PrivateKey.vue'
export default {
  name: 'ExportImportFund',
  components: {
	  PrivateKey
  },
  data: function() {
      return  {
      	showPrivateKey: false,
      	ei: {
      		state: 'pin'
      	},
      	showError: false,
        systemMsg: "",
        pinArr: [],
        otpArr: [],
        ifResent: true,
        timerInSeconds: 60
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		doneExport: function(){
   			this.successExport = false;
   		}, 

   		close: function(){
   			this.$bvModal.hide('pin-key-modal'); 
			this.pinArr = [],
			this.otpArr = [],
			this.systemMsg = ""
			this.showError = false
      this.ifResent = true
      this.timerInSeconds = 60
   		},
   		
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

      generateDigit: function (length) {
        var text = "";
        var possible = "0123456789";

        for (var i = 0; i < length; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
      },
      downloadURI: function(uri, name) {
          var link = document.createElement("a");
          link.download = name;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          //delete link;
        },

        resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {
						          this.init.viewPrivKey.state = 'otp'
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
                      this.showError = true
                      this.systemMsg = error.response.data.msgText
                      console.log(error.response.data.msgText)
                      setTimeout(()=>{
                      this.clearError()
                    },10000);
                  });

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                      console.log('success')
                      this.showPrivateKey = true;

                      setTimeout(() => {
                      	this.$bvModal.show('privatekey-modal'); 
                      },400)
						          
                      // this.$bvModal.hide('pin-key-modal'); 
                    })
                    .catch((error) => {
                        // Error code goes here
						this.showError = true
            this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

        getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cxt-api/address/' + this.assetInfo.symbol  + '/' + this.assetInfo.masterWalletAddress,
          };
          this.axios(options)
            .then((response) => {
               
               this.init.fund = response.data;

              // console.log(this.init.fund.balance);

              this.init.fund.history.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
            });

              this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },

	  isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {
		if(this.init.viewPrivKey.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.init.viewPrivKey.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },
	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }

	.pin-wrapper {
      display: flex;
      width: 66%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
              padding-bottom:7.5px;
      }
    }
	}
	.otp-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
	}

	.btn-upload {
		border-color:lightgray;
		border-width:1px;
		color:gray;
		width:100%;
	}
	
</style>
