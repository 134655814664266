<template>
	<b-modal id="export-import-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				EXPORT/IMPORT CXT
			</h5> 
			<div></div>

    </template>

	<div v-if="ei.state == 'pin'"> 
		<form  v-on:submit.prevent="verifyPin" class="text-center">
			<p class="text-center">Enter your PIN:</p>
			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="pin-wrapper" >
					<div>
					  <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>

					<div>
						<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					  </div>
			
					  <div>
						<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					  </div>
		  
				  </div> 
			</div>
	
			<div class="form-group ">
				<button type="submit" class="btn btn-send">SUBMIT</button>
			</div>

		</form>
	</div>
	<div v-if="ei.state == 'otp'"> 
		<form  v-on:submit.prevent="verifyOtp">
	
			<p class="text-center">Enter the one-time PIN you received:</p>
			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="otp-wrapper" >
				<div>
					<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
				</div>
				</div>
			</div>
	<div class="text-center">
	  <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
	  <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
			</div>
	
			<div class="form-group text-center">
				<button type="submit" class="btn btn-send">SUBMIT</button>
			</div>
		</form>
	</div>
		<div v-if="ei.state == 'export'"> 

			<div class="text-center"> <span class="bold"> BALANCE: </span> {{ formatAmount(init.fund.balance) }}  </div>
	     			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
	     			
			<div class="btn-group email-or-mobile">
            <button type="button" class="btn " v-bind:class="{ active: ei.state === 'export' }" v-on:click="toggleOption('export')">Export</button>
            <button type="button" class="btn " v-bind:class="{ active: ei.state === 'import' }" v-on:click="toggleOption('import')">Import</button> 
          </div>


				<div class="send-container">
					<form v-on:submit.prevent="nextStep('export')">
	     				

	     				<div class="alert alert-success text-center" v-if="successExport">
							 <p>Successfully exported.</p>
	     					<h2>File Secret Key: <span> {{ exportFile.secretKey }} </span></h2>

	     					<h5>IMPORTANT: MUST READ!</h5>

	     					<p>The File Secret Keys provided are dynamically assigned. </p>
	     					<p>Please save immediately and securely.  </p>
	     					<h5>DO NOT LOSE!</h5>

	     					<button class="btn btn-primary" v-on:click="doneExport" type="button">Yes, I already save my file secret key</button>
	     				</div>
	     			
	     			<!-- 	<div class="form-container">
		     				<label for="receipt">File Secret Key:</label>
						  <input type="text" id="receipt" name="receipt" v-model="exportFile.secretKey" class="form-control" readonly>
						  <small>*Note: Please keep your file secret key. You will be needing it to import.</small>
						  <span class="error" v-if="errors.secretKey != ''"> {{ errors.secretKey }} </span> 
	     				</div>
	     			 -->
					  
					  <div class="form-container" v-if="!successExport">
						  <label for="ampunt">Amount:</label>
						  <input type="text" id="amount" name="amount" v-model="exportFile.amount" class="form-control">
						  <span class="error" v-if="errors.amount != ''"> {{ errors.amount }} </span> 
					  </div>
					   
					  
					  <div class="flex  margin-top"  v-if="!successExport">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>EXPORT</span>	
	    				</button>
	    				 
	    			</div>
	    			
	    			
					</form>
				</div> 
			</div>
			<div v-if="ei.state == 'import'"> 
				<div class="text-center"> <span class="bold"> BALANCE: </span> {{ formatAmount(init.fund.balance) }}  </div>
	     			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
	     			
						<div class="btn-group email-or-mobile">
						<button type="button" class="btn " v-bind:class="{ active: ei.state === 'export' }" v-on:click="toggleOption('export')">Export</button>
						<button type="button" class="btn " v-bind:class="{ active: ei.state === 'import' }" v-on:click="toggleOption('import')">Import</button> 
					</div>
				<div class="send-container">
					<form v-on:submit.prevent="nextStep('import')">
	     				
	     			
	     				<div class="form-container">
		     				<label for="receipt">File Secret Key:</label>
						  <input type="text" id="import" name="import" v-model="importFile.secretKey" class="form-control">
						  
						  <span class="error" v-if="errors.importSecretKey != ''"> {{ errors.importSecretKey }} </span> 
	     				</div>
	     			
					  
					  <!-- <div class="form-container">
						  <label for="file">File:</label>
						  <input type="text" id="file" name="file" v-model="importFile.file" class="form-control">
						  <span class="error" v-if="errors.file != ''"> {{ errors.file }} </span> 
					  </div> -->
					   
					   <label for="file">File:</label>
					  <b-form-file
			      v-model="importFile.file"
			      :state="Boolean(importFile.file)"
			      placeholder="Choose a file or drop it here..."
			      drop-placeholder="Drop file here..."
			    ></b-form-file>
			     <span class="error" v-if="errors.file != ''"> {{ errors.file }} </span> 

					  <div class="flex  margin-top">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>IMPORT</span>	
	    				</button>
	    				 
	    			</div>
	    			
	    			
					</form>
				</div> 
			</div>
			<div v-if="ei.state == 'success'" class="text-center">
				<h4>{{ successText }}</h4>
				<button class="btn btn-main" @click="closeReload">CLOSE</button>
			</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'ExportImportFund',
  data: function() {
      return  {
      	ei: {
      		state: 'import'
      	},
      	successExport: false,
      	showError: false,
      	exportFile: {
      		amount: "",
      		secretKey: "",
      	},
      	importFile: {
      		secretKey: "",
      		file: null,
      	},
      	errors: {
      		amount: "",
      		secretKey: "",
      		file: "",
      		importSecretKey: ""
      	},
		selected: "",
		ifResent: true,
		timerInSeconds: 60,
		pinArr: [],
		otpArr: [],
		systemMsg: "",
		showError: false,
		successInfo: {},
		successText: ""
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		doneExport: function(){
   			this.successExport = false;
   			this.errors.amount = ""
   		},
   		toggleOption(selected) {
        this.ei.state = selected; 
  
      },

   		close: function(){
   			this.$bvModal.hide('export-import-modal'); 
			this.ei.state = 'import'
			this.errors.amount = "",
			this.errors.secretKey = "",
			this.errors.file = "",
			this.errors.importSecretKey = ""
			this.exportFile.amount = ""
			this.importFile.secretKey = ""
			this.importFile.file = null
			this.pinArr = []
			   this.otpArr = []
   		},

		closeReload() {
			window.location.reload()
		},

		nextStep(selected) {
			let hasError = false
			if(this.ei.state == 'export') {
				if(this.exportFile.amount == "") {
					hasError = true
					this.errors.amount = "This is required."
					console.log('.')
				}
			}

			if(this.ei.state == 'import') {
				if(this.importFile.secretKey == "") {
					hasError = true
					this.errors.importSecretKey = "This is required."
					console.log('...')
				}
				if(this.importFile.file == null) {
					hasError = true
					this.errors.file = "This is required."
					console.log('....')
				}
			}

		  if(hasError == false) {
			this.ei.state = 'pin'
			this.selected = selected
			console.log(selected)
		  } 
		},

		
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

	  resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {
						this.ei.state = 'otp'
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
						this.showError = true
						this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
						},10000);
					});

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
						console.log(this.selected)
                     if(this.selected == 'export') {
						 this.onExport()
					 } else if(this.selected == 'import') {
						 this.onImport()
					 }
					 this.clearError();
                    })
                    .catch((error) => {
                        // Error code goes here
						this.showError = true
            			this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
        },

		isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {
		if(this.ei.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.ei.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },

      generateDigit: function (length) {
        var text = "";
        var possible = "0123456789";

        for (var i = 0; i < length; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
      },
      downloadURI: function(uri, name) {
          var link = document.createElement("a");
          link.download = name;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          //delete link;
        },

        getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cxt-api/address/' + this.assetInfo.symbol  + '/' + this.assetInfo.masterWalletAddress,
          };
          this.axios(options)
            .then((response) => {
               
               this.init.fund = response.data;

              // console.log(this.init.fund.balance);

              this.init.fund.history.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
            });

              this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },


      onImport: function(){

      	let hasError = false;


      	if(this.importFile.secretKey == '' || this.importFile.secretKey == undefined || this.importFile.secretKey == null) {
					this.errors.importSecretKey = 'Secret key is required.';
					hasError = true;
				}  

				if(this.importFile.file == '' || this.importFile.file == undefined || this.importFile.file == null) {
					this.errors.file = 'File is required.';
					hasError = true;
				}  

				if(!hasError) {
					this.showError = false;

					let formData = new FormData();
      		formData.append('file', this.importFile.file);
      		formData.append('secretKey', this.importFile.secretKey);
      		formData.append('privateKey', this.init.address.issuerReference);


					 this.axios.post('/cxt-api/fund/import',
									formData, {
			            headers: {
			              'Content-Type': 'multipart/form-data'
			            }
			          })
		            .then((response) => { 
						this.ei.state = 'success'
		              this.showError = true;
										this.successText = "You have successfully imported your CXT.";
										this.importFile.file = null;
										this.importFile.secretKey = "";
										this.getFund();
						
						
		            }).catch((err) => {
						console.log('error')
						this.ei.state = 'import'
						this.systemMsg = 'You have successfully imported your CXT.';

						this.pinArr = []
			   			this.otpArr = []
		                if(err) {
		                  this.showError = true;
		                  this.systemMsg = err.response.data.msgText;
										} 

		            }).finally ( ()=> {
										setTimeout(() => {
											this.showError = false;
											this.systemMsg = 'You have successfully imported your CXT.';
											this.errors = { 
												importSecretKey: "",
												file: ""
											}
											this.pinArr = []
						this.otpArr = []

										},3000)
					
					})
				}

      },

       onExport: function(){

      	let hasError = false;

      	if(this.exportFile.amount == '' || this.exportFile.amount == undefined || this.exportFile.amount == null) {
					this.errors.amount = 'Amount is required.';
					hasError = true;
				} else {
					if(this.exportFile.amount < 0) {
						this.errors.amount = 'Amount is required.';
						hasError = true;
					}
				}

				if(!hasError) {
					this.showError = false;
					  this.exportFile.secretKey = this.generateDigit(3) + "-" + this.generateDigit(4);
					 this.axios.post('/cxt-api/fund/export',
						{
							"secretKey": this.exportFile.secretKey,
						    "amount": this.exportFile.amount,
						    "privateKey": this.init.address.issuerReference
						})
		            .then((response) => { 
						this.ei.state = 'success'
		                console.log(response.data);

		                this.downloadURI("data:text/html," + response.data.content, response.data.hash + ".CXT");
										//this.showError = true;
										this.successText = "Fund exported successfully";
										 
										this.exportFile.amount = '';
										 //this.exportFile.secretKey = this.generateDigit(3) + "-" + this.generateDigit(4);
										 this.successExport = true;
										this.getFund();
						
						
		            }).catch((err) => {
			
						this.ei.state = 'export'
		                if(err) {
		                  this.showError = true;
		                  this.systemMsg = err.response.data.msgText;
						} 
						this.pinArr = []
						this.otpArr = []

		            }).finally ( ()=> {
										setTimeout(() => {
											this.showError = false;
											this.systemMsg = '';
											this.errors = { 
												amount: "",
												secretKey: ""
											}
											this.pinArr = []
						this.otpArr = []

										},3000)
					
					})
				}

      }
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
  }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #b48b41;
          border-bottom: 1px solid #b48b41;
        }
      }
 
    }

	.btn-send {
   background-color:orange;
   color:white;
   margin-top:20px;
   padding:5px 20px;
   height:40px;
 }

	.pin-wrapper {
      display: flex;
      width: 66%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
			  padding-bottom:7.5px;
      }
    }
	}
	.otp-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
	}
	
</style>
