<template>
	<b-modal id="change-pin-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				CHANGE PIN
			</h5> 
			<div></div>

    </template>


		<div> 
			<form @submit.prevent="changePin">
				<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
				<div class="form-container">
					<label for="amount">Current PIN:</label>
					<div class="input-group">
						<input type="password" name="amount" @keypress="isNumber($event)" maxlength="6" v-model="pin.current" class="form-control" id="current">
						<div class="input-group-append">
						  <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword('current')" style="margin-top:0px;">{{ hideOrShow.current }}</button>
						</div>
					  </div>
				</div>
				<div class="form-container mt-3">
					<label for="amount">New PIN:</label>
					<div class="input-group">
						<input type="password" name="amount" @keypress="isNumber($event)" maxlength="6" v-model="pin.new1" class="form-control" id="new1">
						<div class="input-group-append">
						  <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword('new1')" style="margin-top:0px;">{{ hideOrShow.new1 }}</button>
						</div>
					  </div>
				</div>
				<div class="form-container mt-3">
					<label for="amount">Confirm New PIN:</label>
					<div class="input-group">
						<input type="password" name="amount" @keypress="isNumber($event)" maxlength="6" v-model="pin.new2" class="form-control" id="new2">
						<div class="input-group-append">
						  <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword('new2')" style="margin-top:0px;">{{ hideOrShow.new2 }}</button>
						</div>
					  </div>
				</div>
				<div class="flex  margin-top mt-3">
					<button  class="btn btn-main" type="submit">  
						<span>Change PIN</span>	
					</button>
					 
				</div>
			</form>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'ExportImportFund',
  data: function() {
      return  {
      	ei: {
      		state: 'export'
      	},
		pin: {
			current: "",
			new1: "",
			new2: "",
		},
      	successExport: false,
      	showError: false,
      	exportFile: {
      		amount: "",
      		secretKey: "",
      	},
      	importFile: {
      		amount: "",
      		file: null,
      	},
      	errors: {
      		amount: "",
      		secretKey: "",
      		file: "",
      		importSecretKey: ""
      	},
		systemMsg: "",
		hideOrShow: {
			current: "Show",
			new1: "Show",
			new2: "Show"
		},
		showPin: {
			current: "password",
			new1: "password",
			new2: "password"
		}
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		doneExport: function(){
   			this.successExport = false;
   			this.errors.amount = ""
   		},
   		toggleOption(selected) {
        this.ei.state = selected; 
  
      },

	  toggleShowPassword: function(toShow){

        if(toShow == "current" && this.hideOrShow.current == "Show") {
          document.getElementById('current').type = 'text';
          this.hideOrShow.current = "Hide"
		} else if(toShow == "current" && this.hideOrShow.current == "Hide") {
          document.getElementById('current').type = 'password';
          this.hideOrShow.current = "Show"
		}
		
		else if(toShow == "new1" && this.hideOrShow.new1 == "Show") {
          document.getElementById('new1').type = 'text';
          this.hideOrShow.new1 = "Hide"
        } else if(toShow == "new1" && this.hideOrShow.new1 == "Hide") {
          document.getElementById('new1').type = 'password';
          this.hideOrShow.new1 = "Show"
        } 
		
		else if(toShow == "new2" && this.hideOrShow.new2 == "Show") {
          document.getElementById('new2').type = 'text';
          this.hideOrShow.new2 = "Hide"
        } else if(toShow == "new2" && this.hideOrShow.new2 == "Hide") {
          document.getElementById('new2').type = 'password';
          this.hideOrShow.new2 = "Show"
        }
      },

   		close: function(){
   			this.$bvModal.hide('change-pin-modal'); 
			this.pin.current = ""
			this.pin.new1 = ""
			this.pin.new2 = ""
			this.systemMsg = ""
			this.showError = false
			this.hideOrShow.current = "Show"
			this.hideOrShow.new1 = "Show"
			this.hideOrShow.new2 = "Show"
			this.toggleShowPassword();
   		},
   		
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

      generateDigit: function (length) {
        var text = "";
        var possible = "0123456789";

        for (var i = 0; i < length; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
      },
      downloadURI: function(uri, name) {
          var link = document.createElement("a");
          link.download = name;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          //delete link;
        },

		changePin() {
			if(this.pin.current.length == 6 && this.pin.new1.length == 6 && this.pin.new2.length == 6) {
            const params = {
                currentPin: this.pin.current,
				newPin1: this.pin.new1,
				newPin2: this.pin.new2
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/changepin',
                };
                this.axios(options)
                    .then((response) => {
						this.showError = true
                        this.systemMsg = "Succesfully changed PIN."
						this.pin.current = ""
						this.pin.new1 = ""
						this.pin.new2 = ""
                    })
                    .catch((error) => {
                        // Error code goes here
						this.showError = true
                        this.systemMsg = error.response.data.msgText
						if(this.systemMsg == "Entered new pins do not match") {
							this.pin.new1 = ""
							this.pin.new2 = ""
						} else if (this.systemMsg == "Invalid current pin") {
							this.pin.current = ""
						}
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
			} else if(this.pin.current.length != 6) {
				this.pin.current = ""
				this.showError = true
				this.systemMsg = "PIN must be 6 digits"
				setTimeout(()=>{
					this.clearError()
				},10000);
			} else if(this.pin.new1.length != 6) {
				this.pin.new1 = ""
				this.showError = true
				this.systemMsg = "PIN must be 6 digits"
				setTimeout(()=>{
					this.clearError()
				},10000);
			} else if(this.pin.new2.length != 6) {
				this.pin.new2 = ""
				this.showError = true
				this.systemMsg = "PIN must be 6 digits"
				setTimeout(()=>{
					this.clearError()
				},10000);
			}
        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

        getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cxt-api/address/' + this.assetInfo.symbol  + '/' + this.assetInfo.masterWalletAddress,
          };
          this.axios(options)
            .then((response) => {
               
               this.init.fund = response.data;

              // console.log(this.init.fund.balance);

              this.init.fund.history.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
            });

              this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },

	  isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },


      onImport: function(){

      	let hasError = false;


      	if(this.importFile.secretKey == '' || this.importFile.secretKey == undefined || this.importFile.secretKey == null) {
					this.errors.importSecretKey = 'Secret key is required.';
					hasError = true;
				}  

				if(this.importFile.file == '' || this.importFile.file == undefined || this.importFile.file == null) {
					this.errors.file = 'File is required.';
					hasError = true;
				}  

				if(!hasError) {
					this.showError = false;

					let formData = new FormData();
      		formData.append('file', this.importFile.file);
      		formData.append('secretKey', this.importFile.secretKey);
      		formData.append('privateKey', this.init.address.issuerReference);


					 this.axios.post('/cxt-api/fund/import',
									formData, {
			            headers: {
			              'Content-Type': 'multipart/form-data'
			            }
			          })
		            .then((response) => { 
		              this.showError = true;
										this.systemMsg = "Fund imported successfully";
										this.importFile.file = null;
										this.importFile.secretKey = "";
										this.getFund();
						
						
		            }).catch((err) => {
			
			
		                if(err) {
		                  this.showError = true;
		                  this.systemMsg = err.response.data.msgText;
										} 

		            }).finally ( ()=> {
										setTimeout(() => {
											this.showError = false;
											this.systemMsg = '';
											this.errors = { 
												importSecretKey: "",
												file: ""
											}
										},3000)
					
					})
				}

      },

       onExport: function(){

      	let hasError = false;

      	if(this.exportFile.amount == '' || this.exportFile.amount == undefined || this.exportFile.amount == null) {
					this.errors.amount = 'Amount is required.';
					hasError = true;
				} else {
					if(this.exportFile.amount < 0) {
						this.errors.amount = 'Amount is required.';
						hasError = true;
					}
				}

				if(!hasError) {
					this.showError = false;
					  this.exportFile.secretKey = this.generateDigit(3) + "-" + this.generateDigit(4);
					 this.axios.post('/cxt-api/fund/export',
						{
							"secretKey": this.exportFile.secretKey,
						    "amount": this.exportFile.amount,
						    "privateKey": this.init.address.issuerReference
						})
		            .then((response) => { 
		             
		                console.log(response.data);

		                this.downloadURI("data:text/html," + response.data.content, response.data.hash + ".CXT");
										//this.showError = true;
										//this.systemMsg = "Fund exported successfully";
										 
										this.exportFile.amount = '';
										 //this.exportFile.secretKey = this.generateDigit(3) + "-" + this.generateDigit(4);
										 this.successExport = true;
										this.getFund();
						
						
		            }).catch((err) => {
			
			
		                if(err) {
		                  this.showError = true;
		                  this.systemMsg = err.response.data.msgText;
										} 

		            }).finally ( ()=> {
										setTimeout(() => {
											this.showError = false;
											this.systemMsg = '';
											this.errors = { 
												amount: "",
												secretKey: ""
											}
										},3000)
					
					})
				}

      }
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #b48b41;
          border-bottom: 1px solid #b48b41;
        }
      }
 
    }
	
</style>
