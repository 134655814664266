<template>
    <div>   

        <div class="chat-button">
            

            <div class="chat-wrapper" v-if="init.showChat"> 
                
                <div class="chat-window">
                    
                    <div class="chat-content">
                        
                        <div class="chat-content-init"  v-if="chatState == 'init'">
                            <h2>Hi,</h2>

                            <p>Send a message by pasting their wallet address below.</p>

                            <div class="form-group">
                                <input type="text" name="pubKey" class="form-control" v-model="chat.currentRecipientAddress" placeholder="Enter wallet address">
                                <p class="help-block" v-if="error.currentRecipientAddress != ''"> {{ error.currentRecipientAddress }} </p>
                            </div>

                            <div class="form-group">
                                <button class="btn btn-start-chat" v-on:click="startMsg"> Start Chat </button>
                            </div>
                        </div>

                        <div class="history" v-if="chatState == 'init'">
                            <div class="contact-list-button" v-on:click="initContact">
                                
                                <span v-if="hasNewMsg">New Message Received</span>
                                <span v-if="!hasNewMsg">Contacts List</span>
                            </div>

                        </div>

                        <div class="history" v-if="chatState == 'contact-list'">
                           
                           <div class="chat-content-message-header">
                                <div class="exit-header" v-on:click="exitChat">
                                    <div><img class="exit-chat-logo" :src="require('../../assets/back.svg')"></div>
                                    <div class="exit-chat">New Message</div>
                                </div>
 
                            </div>
 
                            <h4 class="no-contact"  v-if="contactList.length <= 0"> No Contact  </h4>

                            <div class="contact-list-wrapper">
                                <div class="contact" v-for="contact in contactList" v-on:click="startChatFromList(contact.contactAddress)">
                                    
                                    <span class="contact-address">{{ contact.contactAddress }}</span>

                                    <span class="new-msg" v-if="newFriendMsg.includes(contact.contactAddress)">New message</span>
                                </div>
                            </div>

                        </div>

                        <div class="chat-content-message"  v-if="chatState == 'message'">
                            <div class="chat-content-message-header">
                                <div class="exit-header" v-on:click="exitChat">
                                    <div><img class="exit-chat-logo" :src="require('../../assets/back.svg')"></div>
                                    <div class="exit-chat">Exit Chat</div>
                                </div>

                                <div class="label-name"> {{ chat.currentRecipientAddress }}</div>
                            </div>

                            <div class="chat-content-message-content" id="msgContainer">
                                
                                <div id="msgLoading" v-if="showChatLoading">
                                    <div class="content">
                                 
                                        <v-icon name="spinner" pulse spin scale="3" />

                                    </div>
                                </div> 

                                <div v-if="!showChatLoading" v-for="msg in convMessages" class="msg-box" >
                                    
                                    <div class="msg-content"  v-bind:class="[ msg.from == ownId  ? 'own-box' : 'friend-box']">
                                        <div class="message" v-bind:class="[ msg.from == ownId  ? 'own' : 'friend']"> <span v-html="formatMessage(msg.message)"> </span></div>
                                    </div> 
                                    <span class="chat-date" v-bind:class="[ msg.from == ownId  ? 'own-time' : 'friend-time']"> {{ formatDate(msg.formattedCreatedAt) }} </span>
                                    
                                </div>
                                
                            </div>

                            <div class="chat-content-message-footer" v-if="!showChatLoading">
                                <div>
                                    <textarea class="form-control" name="" v-on:keyup.enter="sendMsg" v-model="msg" placeholder="Write a message"> </textarea>
                                </div>

                                <div><img v-on:click="sendMsg" class="send-logo" :src="require('../../assets/send.svg')"></div>
                            </div>
                        </div>

                    </div>


                   

                </div>

            </div>

            <div>
                <span v-if="hasNewMsg" class="msg-indicator">!</span>
                <img class="chat-logo"  v-on:click="toggleChatWindow" :src="require(`@/assets/${chatLogo}`)">
            </div>
            
        </div>
        
    </div>
</template>

<script>
 

  export default {
    name: 'chat',
    components: {
    },
    data: function() {
        return  { 
            chat: {
                currentRecipientAddress: "",
            },
            rawAddressId: "",
            chatLogo: "chat-button.png",
            chatState: "init",
            showChatLoading: true,
            connection: null,
            ownId: "",
            contactId: "",
            convMessages: [],
            msg: "",
            currenConversationId: "", 
            error: {
                currentRecipientAddress: ""
            },
            contactList: [],
            hasNewMsg: false,
            newFriendMsg: []
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        formatDate: function(date){

            var offset = new Date().getTimezoneOffset();
            offset =  (offset/60) * -1;
            var dateRaw = new Date(date);

            return new Date(new Date(dateRaw).setHours(dateRaw.getHours() + offset)).toString().substr(0,24);
        },
        initContact: function(){
            this.chatState = 'contact-list'
            this.hasNewMsg = false;

            if(this.contactList.length <= 0) {
                this.getContacts();
            }
        },
        startChatFromList: function(address){ 

            const index = this.newFriendMsg.indexOf(address);
            if (index > -1) {
                this.newFriendMsg.splice(index, 1); 
            }


            this.chat.currentRecipientAddress = address;
            this.getDetailsPerAddress(this.chat.currentRecipientAddress, "contact");
            this.chatState = "message"; 
            this.showChatLoading = true;
        },
        formatMessage: function(msg){
            const arryMsg = msg.split(" ");
            let formattedMsg = "";
            
            arryMsg.forEach((el,i) => {
                
                if(this.isValidHttpUrl(el)) {
                    el = "<a target='_blank' href='"+ el +"'>" + el + "</a>"
                }

                formattedMsg += (" " + el);
            });
  
            return msg;
        },
        isValidHttpUrl: function(string) {
          let url;
          
          try {
            url = new URL(string);
          } catch (_) {
            return false;  
          }

          return url.protocol === "http:" || url.protocol === "https:";
        },
        sendMsg: function(event){ 

            if (event.shiftKey===true && event.key === "Enter") { 
               return;
           }

            const params = {
                from: this.ownId,
                conversationId: this.currenConversationId,
                type: "TEXT",
                message: this.msg,
                lat: 0,
                long: 0,
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    
            this.connection.emit('messages:save', params);


            this.connection.on('messages:save:response', (response) => { 
                this.getMessages(response.data.conversationId, this.ownId);
                this.msg = "";
            });
            // const options = {
            //     method: 'POST',
            //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
            //     data,
            //     url: '/messages/save',
            // };
            // this.axios(options)
            //     .then((response) => {
            //         //console.log(response);

            //         this.getMessages(response.data.data.conversationId, this.ownId);
            //         //this.connection.emit('messages:save', params);

                   
            //         this.msg = "";
            //     })
            //     .catch((error) => {
            //         console.log(error)
            //     });
        },
        getChatIcon: function() {
            return require('../../assets/' + this.chatLogo);

        },
        toggleChatWindow: function(){
            
            this.init.showChat = !this.init.showChat;
 
            if(this.init.showChat) {
                this.chatLogo = "down.png";
            } else {
                this.chatLogo = "chat-button.png";
            }

            setTimeout(() => {
                this.scrollToBottom();
            },500)
        },
        startMsg: function(){

            

            let hasError = false;
            if(this.chat.currentRecipientAddress == "") {
                this.error.currentRecipientAddress = "Wallet address is required.";
                hasError = true;
            } else {

                if(this.chat.currentRecipientAddress.length < 33 || this.chat.currentRecipientAddress.length > 35) {
                    this.error.currentRecipientAddress = "Enter invalid address.";
                    hasError = true;
                }

                if(this.chat.currentRecipientAddress == this.rawAddressId) {
                    this.error.currentRecipientAddress = "Error: Own address";
                    hasError = true;
                }

            }

            if(!hasError) {
                this.addContact();
                this.getDetailsPerAddress(this.chat.currentRecipientAddress, "contact");
                this.chatState = "message"; 
                this.showChatLoading = true;
            }

            
        },
        exitChat: function (){
            this.chat.currentRecipientAddress = "";
            this.chatState = "init";
        },
        getDetailsPerAddress: function(address, type){
 
            const params = {
                entityIds: address
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/users/by_entity_ids',
            };
            this.axios(options)
                .then((response) => { 

                    if(type == "own") {
                        this.ownId = response.data.data[0].id;
                    } else if(type == "contact") {
                        this.contactId = response.data.data[0].id;
                    }
 
                    if(this.contactId != "") {
                        this.initHistory(this.ownId, this.contactId);
                    }
                    
                })
                .catch((error) => {
                    console.log(error)
                });
        },

        initHistory: function(userId, otherUserIds){
            const params = {
                userId: userId,
                otherUserIds: otherUserIds,
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/conversations/private/by_user_ids',
            };
            this.axios(options)
                .then((response) => {
                    //console.log(response);
                    //console.log("Conversation ID: " + response.data.data[0].id);
                    this.currenConversationId = response.data.data[0].id;
                    this.convMessages = [];
                    this.getMessages(response.data.data[0].id, userId);
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        scrollToBottom: function(){
            var objDiv = document.getElementById("msgContainer");
 
            if(objDiv != null) {

                setTimeout(() => { 
                        
                    objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;

                },100);
                
            }
            
        },
        getMessages: function(conversationId, userId){


            const params = {
                conversationId: conversationId,
                userId: userId,
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/messages/list/by_conversation_id',
            };
            this.axios(options)
                .then((response) => {
                    //console.log(response); 
                    this.convMessages = response.data.data;
                    
                    setTimeout(() => { 
                        
                        this.showChatLoading = false;

                        this.scrollToBottom();

                    },500);

                    
                })
                .catch((error) => {
                    console.log(error)
                });
        },

        getContacts: function(){
            const options = {
                method: 'GET', 
                url: '/cx/contacts/list',
            };
            this.axios(options)
                .then((response) => {
                   console.log(response)
                   this.contactList = response.data;
                    
                })
                .catch((error) => {
                    console.log(error)
                });
        },

        addContact: function(){
            const params = {
                publickey: this.chat.currentRecipientAddress
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/contact/add',
            };
            this.axios(options)
                .then((response) => { 
                    this.getContacts();
                })
                .catch((error) => {
                    console.log(error)
                });
        },

        initChat: function(){
         const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/address/CXT',
          };

          this.axios(options)
            .then((response) => {

                this.rawAddressId = response.data.addressValue;
                var data = {
                    entityId:this.rawAddressId,
                    profile: {}
                }; 
                this.connection.emit('user:join', data);

                this.getDetailsPerAddress(this.rawAddressId, "own");

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },
    },

    mounted() {
        


    },
    created: function() {
        this.getContacts();
        setTimeout(() => { 
    
            this.connection = io('wss://chat.blx.org:4433/', {transports: ['websocket']});
            this.initChat();

           
            
            this.connection.on('messages:new', (response) => {
                var audio = new Audio(require('../../assets/notif.mp3'));
                audio.play();

                if(!this.newFriendMsg.includes(response.fromUser.entityId)) {
                    this.newFriendMsg.push(response.fromUser.entityId);
                }
                
                if(!this.init.showChat) {

                    console.log(response.fromUser.entityId);
                    console.log(response);
                    this.hasNewMsg = true;
                    this.init.showChat = true;
                } else {
                    
                    this.hasNewMsg = true;
                    if(this.chat.currentRecipientAddress == response.fromUser.entityId) {

                        this.scrollToBottom();
                        this.getMessages(response.conversationId, this.ownId);
                    }
                    
                }

                
                 
                
            });


        },500)


    },
    watch: {
            'chat.currentRecipientAddress':function(newVal, oldVal) {
                if(newVal != oldVal) {
                    this.error.currentRecipientAddress = '';
                }
            },
        }
  }
</script>

<style scoped lang="scss">

@keyframes spinner {
  to { transform: rotate(360deg); }
}
/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spin {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}   

    .msg-indicator {
        background: red;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        position: absolute;
        text-align: center;
        color: #FFF; 
        right: 0; 
        z-index: 999999;
    }

    .new-msg {
        background: #D99328;
        padding: 0.5em;
        font-size: 0.6em;
        color: #FFF;
        border-radius: 6px;
        margin-left: 1em;
    }

    .contact {
        margin: 1em;    
        padding: 2em 0.5em;
        background: #FFF;
        font-size: 0.75em;
        border-radius: 6px;
        cursor: pointer;
    }

    .contact-address {
        color: #b48b41;
    }
    .no-contact {
        text-align: center;
        margin-top: 1em;
    }
    .contact-list-button {
        border: 2px solid #b48b41;
        border-radius: 2em;
        margin: 1em;
        padding: 1em;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        color: #b48b41;

        &:hover {
            background: #FFF;
        }
    }

    .chat-date {
        font-size: 0.5em;
        display: flex;
        padding: 0 2em;
        margin-top: -1.5em;
       
        &.own-time {
             justify-content: right;
        }

        &.friend-time {
             justify-content: left;
        }
    }
 
    @font-face {
        font-family: 'Montserrat-Light';
        src: url(../../assets/font/Montserrat-Light.otf);
        font-style: normal;
      }
    .btn-start-chat {
        width: 100%;
        background: #2D2D2D;
        color: #FFF;

        &:hover {
            background: darken(#2D2D2D, 10%);
        }
    }

    .send-logo {
        width: 35px;
        cursor: pointer;
    }

    .label-name {
        margin-top: 1em;
        font-size: 0.8em;
    }

    .exit-chat-logo {
        width: 12px;
        margin-right: 0.5em;
    }

    #msgLoading {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .chat-content-message-header {
        padding: 1em;
        background: #D99328;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        color: #FFF;
        display: flex;
        flex-direction: column;

        .exit-header {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }


    .chat-wrapper {
        position: relative;
        z-index: 99999;

        .chat-window {
            background: #F4F4F4;
            position: absolute;
            bottom: 1em;
            right: 0;
            width: 23em;
            
            border-radius: 6px;
            height: 35em;

            .chat-content {
                height: 100%;

                .chat-content-init {
                    padding: 1em;
                    background: #D99328;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    color: #FFF;
                }

                .chat-content-message {
                    position: relative;
                    height: 100%;
                    

                    .msg-content {

                        display: flex;
                        width: 100%;

                        &.own-box {
                            justify-content: right;
                        }

                        &.friend-box {
                            justify-content: left;
                        }
                        

                        .message {
                            width: fit-content;
                            max-width: 75%;
                            padding: 0.75em;
                            color: #FFF;
                            margin: 1em;
                            border-radius: 6px;
                            overflow-wrap: break-word;
                            white-space: break-spaces;
                            &.own {
                                background: #DD8615;
                            }

                            &.friend {
                                background: #2D2D2D;
                            }
                        }
                    }

                    .chat-content-message-content {
                        background: #F4F4F4;
                        height: 24em;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        font-family: 'Montserrat-Light';

                        .msg-box {

                            
                        }


                    }

                    .chat-content-message-footer {
                        border-top: 2px solid #000;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        border-bottom-right-radius: 6px;
                        border-bottom-left-radius: 6px;
                        display: flex;
                        align-items: center;
                        background: #FFF;
                        justify-content: space-around;

                        textarea {
                            resize: none;
                            width: 100%;
                            padding: 0.5em;
                            outline: none;
                            border: none;
                            box-shadow: none;
                            border-bottom-right-radius: 6px;
                            border-bottom-left-radius: 6px;
                            font-family: 'Montserrat-Light';
                        }
                    }

                    .exit-chat {
                        cursor: pointer;
                    }
                }
            }

        }
    }

    .chat-button {
        position: fixed;
        right: 1em;
        bottom: 1em;
        

        .chat-logo {
            width: 50px;
            height: 50px;
            cursor: pointer;
        }
    }

</style>



  