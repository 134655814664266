<template>
    <div class="b-modal">
        <div v-if="state == 1">
            <div class="modalBody formWrapper text-white">
                <h3>Hi,</h3>
                <h6>Send a message by pasting their  wallet address below.</h6>
                <form @submit.prevent="openChat">
                    <input type="text" class="form-control" placeholder="Enter Wallet Address">
                    <button class="btn submit btn-dark mt-1" type="submit">SEND</button>
                </form>
            </div>
            <div class="modalBody banner">
                <div class="shadow p-3" style="border-radius:5px;">
                    <h6>
                      BLOX VOTING NOW LIVE!
                    </h6>
                </div>
                <div class="d-flex flex-wrap mt-3 shadow p-3" style="border-radius:5px;">
                    <img src="../../assets/fab-01.png" alt="" style="width:3em;">
                    <div style="width:75%;" class="mt-1 ml-3">
                        <h6>
                            JOIN OUR TELEGRAM COMMUNITY!
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="state == 2">
            <div class="header p-3">
                <div class="d-flex" style="width:50%; cursor: pointer;"> 
                <div class="mr-3"><v-icon name="chevron-left" class="chevron-left" /></div>
                <h5 class="mt-2">EXIT CHAT</h5>
            </div>
            </div>
            <div class="chat">
                <div class="chatBody" style="overflow-y:scroll;">
                    <div class="px-3 py-2 text-right" v-for="chat in chats" :key="chat.id">
                        <div class="text-right text-white mt-1 mb-1" v-if="chat.sent"><span class="textbox-send">{{ chat.message }}</span></div>
                        <div class="text-left text-white mt-1 mb-1" v-if="!chat.sent"><span class="textbox-receive">{{ chat.message }}</span></div>
                    </div>
                </div>
                <input id="chatInput" class="form-control p-2" type="text" placeholder="Write a message..." v-model="message" @keydown.enter="sendMessage">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    data: function() {
        return {
            state: 1,
            chats: [],
            message: "",
            ifSent: true,
        }
    },

    computed: {
      
    },


    watch: {
        // 'signupData.loginId': function(newValue, oldValue) {
        //     if (newValue != oldValue) {
        //         this.errorMsg = ''
        //     }
        // },
        // 'signupData.loginPass': function(newValue, oldValue) {
        //     if (newValue != oldValue) {
        //         this.errorMsg = ''
        //     }
        // },
    },

    methods: {
        closeModal(){
            this.$emit('close')
            this.state = 1
            this.chats = []
            this.message = ""
            this.ifSent = true
        },

        openChat() {
            this.state = 2
        },

        sendMessage() {
            if(this.message != "") {
                this.chats.push({message: this.message, sent: this.ifSent})
                this.message = ""
                this.ifSent = !this.ifSent
                console.log(this.chats)
            }
        }
    },

    mounted() {
    },
}
</script>

<style scoped>
.b-modal {
    width: 350px;
    height: auto;
    margin: 5px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 4;
    overflow-x: hidden !important;
    color:black;
}

.header {
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
    background-image: linear-gradient(to right, #b48b41, #b48b41);
    color:white;
    fill:white;
}

.modal-title{
    margin: 0 auto;
    color:black;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:25px;
}

.formWrapper {
    background-image: linear-gradient(to right, #b48b41, #b48b41);
}

.submit {
    width:100%;
}

.chat {
    margin-bottom:0px;
}

#chatInput {
    width:100%;
    padding:5px;
    border-top:1px black solid;
}

.chatBody {
    max-height:425.58px;
}

.chatBody::-webkit-scrollbar {
    display:none;
}

.textbox-send {
    background-image: linear-gradient(to right, #b48b41, #b48b41);
    padding:10px;
    margin-top:5px;
    border-radius:5px;
}

.textbox-receive {
    background-color:black;
    padding:10px;
    margin-top:5px;
    border-radius:5px;
}


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
</style>