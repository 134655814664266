<template>
	<b-modal id="redeem-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				REDEEM FROM VERUM COIN
			</h5> 
			<div></div>

    </template>


		<div  > 
				<div class="send-container" >
					<form v-on:submit.prevent="onRedeem">
	     				<div class="text-center"> <span class="bold"> BALANCE: </span> {{ formatAmount(init.fund.balance) }}  </div>
	     			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
	     			
	     			
	     				<div class="form-container">
		     				<label for="receipt">Serial No.:</label>
						  <input type="text" id="receipt" name="receipt" v-model="redeem.serial" class="form-control" >
						  <span class="error" v-if="errors.serial != ''"> {{ errors.serial }} </span> 
	     				</div>
	     			
					  
					  <div class="form-container">
						  <label for="ampunt">Hash:</label>
						  <input type="text" id="amount" name="amount" v-model="redeem.hash" class="form-control">
						  <span class="error" v-if="errors.hash != ''"> {{ errors.hash }} </span> 
					  </div>
					   
					  
					  <div class="flex  margin-top">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>Redeem</span>	
	    				</button>
	    				 
	    			</div>
	    			
	    			
					</form>
				</div> 
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 

export default {
  name: 'Redeem',
  data: function() {
      return  {
      	redeem: {
					serial: "",
					hash: ""
				},
				showError: false,
				errors: {
					serial: "",
					hash: ""
				},
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		close: function(){
   			this.$bvModal.hide('redeem-modal'); 
   		},
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

       getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cxt-api/address/CXT/' + this.init.address.addressValue,
          };

          this.axios(options)
            .then((response) => {
               
               this.init.fund = response.data;

              // console.log(this.init.fund.balance);

              this.init.fund.history.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
            });

              this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },

      onRedeem: function(){
				let hasError = false;
				
				if(this.redeem.serial == '' || this.redeem.serial == undefined || this.redeem.serial == null) {
					this.errors.serial = 'Serial no. is required.';
					hasError = true;
				}
				
				if(this.redeem.hash == '' || this.redeem.hash == undefined || this.redeem.hash == null) {
					this.errors.hash = 'Hash is required.';
					hasError = true;
				} 
				
				
				//console.log(hasError);
				if(!hasError) {
					this.showError = false;

					const params = {
          	serial: this.redeem.serial,
		          hash: this.redeem.hash
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/cx/redeem/coin',
		        };


					 this.axios(options)
		            .then((response) => {  
										this.showError = true;
										this.systemMsg = "Redeemed successfully. Your account will be credited shortly." ;
										
										 this.redeem.serial = '';
										 this.redeem.hash = ''; 
										this.getFund();
						
						
		            }).catch((err) => {
			
			
		                if(err) {
		                  	 this.showError = true;
						
												this.systemMsg = err.response.data.msgText;
							
										} 

		            }).finally ( ()=> {
						setTimeout(() => {
							this.showError = false;
							this.systemMsg = '';
							this.errors = {
								redeem: "",
								hash: "",
							}
						},3000)
					
					})
				}
				
			},
   	},
   	beforeMount(){
 		},



}
</script>
 
<style scoped lang="scss">
	  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

   .error {
  	color: red;
  }

  button {
  	margin-top:1em;
  }


	
</style>
