<template>
	<b-modal id="send-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

	<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" class="icon" v-if="state == 'send'"/>
			</div>
			<h5>
				SEND CXT
			</h5> 
			<div></div>

    </template>
	<div v-if="state == 'send'"> 
		<div class="send-container" >
			<form v-on:submit.prevent="onSend">
				 <div class="text-center"> <span class="bold"> BALANCE: </span> {{ formatAmount(init.fund.balance) }}  </div>
			 <div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			 
			 
				 <div class="form-container">
					 <label for="receipt">Recipient Address:</label>
				  <input type="text" id="receipt" name="receipt" v-model="send.address" class="form-control" minlength="30" maxlength="66">
				  <span class="error" v-if="errors.address != ''"> {{ errors.address }} </span> 
				 </div>
			 
			  
			  <div class="form-container">
				  <label for="ampunt">Amount:</label>
				  <input type="text" id="amount" name="amount" v-model="send.amount" class="form-control">
				  <span class="error" v-if="errors.amount != ''"> {{ errors.amount }} </span> 
			  </div>
			   
			  
			  <div class="flex  margin-top">
				<button  class="btn btn-main" type="submit" :disabled="isDisabled">  
					<span>Send</span>	
				</button>
				 
			</div>
			
			
			</form>
		</div> 
	</div>
	<div v-if="state == 'pin'"> 
		<form  v-on:submit.prevent="verifyPin" class="text-center">
			<p class="text-center">Enter your PIN:</p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="pin-wrapper" >
					<div>
					  <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>

					<div>
						<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					  </div>
			
					  <div>
						<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					  </div>
		  
				  </div> 
			</div>
	
			<div class="form-group ">
				<button type="submit" class="btn btn-send">SUBMIT</button>
			</div>

		</form>
	</div>
	<div v-if="state == 'otp'"> 
		<form  v-on:submit.prevent="verifyOtp">
	
			<p class="text-center">Enter the one-time PIN you received:</p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="otp-wrapper" >
				<div>
					<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
				</div>
				</div>
			</div>
	<div class="text-center">
	  <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
	  <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
			</div>
	
			<div class="form-group text-center">
				<button type="submit" class="btn btn-send">SUBMIT</button>
			</div>
		</form>
	</div>
	<div v-if="state == 'success'"> 
		<div class="text-center">
			<h5>
			You successfully sent {{ formatAmount(successInfo.amount) }} CXT to  {{ successInfo.recipient }}
			</h5>
			<button class="btn btn-main" @click="closeReload">DONE</button>
		</div>
	</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'Send',
  data: function() {
      return  {
      	send: {
					address: "",
					amount: "",
					privateKey: ""
				},
				showError: false,
				errors: {
					address: "",
					amount: "",
					privateKey: ""
				},
				state: 'send',
		ifResent: true,
		timerInSeconds: 60,
		pinArr: [],
		otpArr: [],
		systemMsg: "",
		showError: false,
		successInfo: {},
		isDisabled: false,
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		close: function(){
			
   			this.$bvModal.hide('send-modal'); 
			   this.state = 'send'
			   this.pinArr = []
			   this.otpArr = []
			   this.errors.address = ""
			   this.errors.amount = ""
			   this.errors.privateKey = ""
   		},
		closeReload: function(){
			
			window.location.reload();
		},
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

       getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cxt-api/address/CXT/' + this.init.address.addressValue,
          };

          this.axios(options)
            .then((response) => {
               
               this.init.fund = response.data;

              // console.log(this.init.fund.balance);

              this.init.fund.history.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
            });

              this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },

	  nextStep() {
		  let hasError = false
		  if(this.send.address == "") {
			  hasError = true
			  this.errors.address = "This is required."
		  }
		  if(this.send.amount == "") {
			  hasError = true
			  this.errors.amount = "This is required."
		  }

		  if(hasError == false) {
		this.state = 'pin'
		this.errors.address = ""
		this.errors.amount = ""
		this.errors.privateKey = ""
		  } else {
			  console.log(hasError)
		  }
	  },

	  resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {
						this.state = 'otp'
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
						this.showError = true
						this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
						},10000);
					});

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                     this.confirmSend();
					 this.clearError();
                    })
                    .catch((error) => {
                        // Error code goes here
						this.showError = true
            			this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
        },

		isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {
		if(this.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },

      onSend() {
		  let hasError = false;
				
				if(this.send.address == '' || this.send.address == undefined || this.send.address == null) {
					this.errors.address = 'Recipient wallet address is required.';
					hasError = true;
				}

				if(this.send.address == this.init.address.addressValue) {
					this.errors.address = "You can't send to your own wallet address"
				}
				
				if(this.send.amount == '' || this.send.amount == undefined || this.send.amount == null) {
					this.errors.amount = 'Amount is required.';
					hasError = true;
				} else {
					if(this.send.amount < 0) {
						this.errors.amount = 'Amount is required.';
						hasError = true;
					}
				}

				if(!hasError) {
					this.isDisabled = true;
					this.showError = false;
						this.axios.post('/cxt-api/send/init')
							.then((response) => { 
								this.txnControlId =  response.data.txnControlId;
								
								this.axios.post('/cxt-api/send/submit',{
								
									"address": this.send.address,
									"amount": this.send.amount,
									"privateKey": this.init.address.issuerReference,
									"symbol": "CXT",
									"txnControlId": this.txnControlId,

								})
								.then((response) => { 
									this.nextStep();
								}).catch((err) => {
					
								console.log(err)

								this.state = 'send'
								this.isDisabled = false
								if(err) {
									this.showError = true;
											
												if(err.response.data.msgCode == "99999") {
													this.systemMsg = "Invalid Private Key."	
												} else {
														this.systemMsg = err.response.data.msgText
												} 
												
												this.pinArr = []
								   				this.otpArr = []
											}  
											this.timerInSeconds = 60
		            }).finally ( ()=> {
									setTimeout(() => {
										this.showError = false;
										this.systemMsg = '';
										this.errors = {
											address: "",
											amount: "",
											privateKey: ""
										}
									},3000)
								
								});

						
		            }).catch((err) => {
			
									console.log(err)
		            })
				}

	  },

      confirmSend: function(){
				let hasError = false;
				
				if(this.send.address == '' || this.send.address == undefined || this.send.address == null) {
					this.errors.address = 'Recipient wallet address is required.';
					hasError = true;
				}
				
				if(this.send.amount == '' || this.send.amount == undefined || this.send.amount == null) {
					this.errors.amount = 'Amount is required.';
					hasError = true;
				} else {
					if(this.send.amount < 0) {
						this.errors.amount = 'Amount is required.';
						hasError = true;
					}
				}
				
				
				/*if(this.send.privateKey == '' || this.send.privateKey == undefined || this.send.privateKey == null) {
					this.errors.privateKey = 'Private key is required.';
					hasError = true;
				}*/
				
				//console.log(hasError);
				if(!hasError) {
					this.showError = false;
					 this.axios.post('/cxt-api/send/confirm',
						{
							"txnControlId": this.txnControlId,
						})
		            .then((response) => { 
						this.state = 'success'
						this.successInfo = response.data
						
						
		            }).catch((err) => {
			
						this.state = 'send'
						this.isDisabled = false
		                if(err) {
		                  	 this.showError = true;
						
							if(err.response.data.msgCode == "99999") {
								this.systemMsg = "Invalid Private Key."	
							} else {
								if(err.response.data.msgText == "Minimum amount requirement: 1.0E-4") {
									let text  = "Insufficient Balance"
									this.systemMsg = text
								} else {
									this.systemMsg = err.response.data.msgText
								}
							} 
							
						} 

		            }).finally ( ()=> {
						setTimeout(() => {
							this.showError = false;
							this.systemMsg = '';
							this.errors = {
								address: "",
								amount: "",
								privateKey: ""
							}
						},3000)
					
					})
				}
				
			},
   	},
   	beforeMount(){
		   this.getFund();
 		},

}
</script>
 
<style scoped lang="scss">
	  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

  

   .error {
  	color: red;
  }

  button {
  	margin-top:1em;
  }

  .btn-send {
   background-color:orange;
   color:white;
   width:100%;
   margin-top:10px;
 }

  .otp-wrapper, .pin-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
	}


	
</style>
