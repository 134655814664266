<template>
  <div  class="main-container">  
    <img :src="require('../assets/wallet-bg.jpg')" class="bg">

     <div id="loading" v-if="showLoading">
      <div class="content">
 
        <v-icon name="spinner" pulse scale="3" />

      </div>
    </div>


    <div class="header">
      <div class="d-flex flex-wrap" style="width:600px;"> 
        <b-dropdown :text="init.profile.fullName" class="m-md-2 btn-black">
          <div class="p-2" id="walletAddress">{{ init.address.addressValue}}</div>
          <b-dropdown-item class="add-btn" @click="initPin">Change PIN</b-dropdown-item>
          <b-dropdown-item class="add-btn" @click="logout" v-if="windowWidth <= 800">Logout</b-dropdown-item>
       </b-dropdown>
       <b-dropdown id="dropdown-2" class="btn-black m-md-2" text="Get Private Key">
        <b-dropdown-item class="add-btn" @click="initPrivateKey">View</b-dropdown-item>
        <b-dropdown-item class="add-btn" @click="initImportKey">Import</b-dropdown-item>
        <b-dropdown-item class="add-btn" @click="initGenerateKey('show')">Generate</b-dropdown-item>
      </b-dropdown>
      </div>
      
      <div class="d-flex" style="align-items: end; justify-content: end; text-align: end;">
        <a href="#" class="logout" v-on:click="logout">Logout</a>
      </div>
    </div>
    <div class="btn-wrapper" style="width:100%">
      <!-- <div class="d-flex flex-wrap justify-content-center" style="width:100%">
        <button class="btn cancel" :class="{ active: state.mainState == 'wallet'}" style="width:45%" @click="changeState('wallet', 'main')">MAIN WALLET</button>
        <button class="btn cancel" :class="{ active: state.mainState == 'subwallet'}" style="width:45%" @click="changeState('subwallet', 'main')">SUBWALLET</button>
      </div> -->
      <!-- <div class="d-flex flex-wrap mt-3 justify-content-center" style="width:100%" v-if="state.mainState == 'wallet'">
        <button class="btn cancel" :class="{ active: state.subState == 'wallet'}" style="width:30%" @click="changeState('wallet', 'sub')">MY CXT</button>
        <button class="btn cancel" :class="{ active: state.subState == 'token'}" style="width:30%" @click="changeState('token', 'sub')">MY TOKENS</button>
        <button class="btn cancel" :class="{ active: state.subState == 'nft'}" style="width:30%" @click="changeState('nft', 'sub')">MY NFTS</button>
      </div>
      <div class="d-flex flex-wrap mt-3 justify-content-center" style="width:100%" v-if="state.mainState == 'subwallet'">
          <button class="btn cancel" :class="{ active: state.assetState == 'token'}" style="width:45%" @click="changeState('token', 'asset')">TOKENS RECEIVED</button>
          <button class="btn cancel" :class="{ active: state.assetState == 'nft'}" style="width:45%" @click="changeState('nft', 'asset')">NFTS RECEIVED</button>
      </div> -->
    </div>
  <div v-if="state.mainState == 'wallet' && state.subState == 'wallet'">
    <div class="logo">
      <!-- <img :src="require('../assets/logo.png')" > -->
   </div>
   
   <div class="content"> 
     <div class="balance">
       <!-- <img :src="require('../assets/wallet-bg.jpg')" class="bal-bg"> -->
         <div class="z-index-2">
           <img :src="require('../assets/wallet.png')" class="content-icon">
         </div>
       <div class="z-index-2">
         {{ init.address.addressValue }}
       </div >
       <div class="z-index-2">
         Wallet Balance
       </div>
       <div class="z-index-2">
         CXT {{ formatAmount(init.fund.balance) }}
       </div>  
     </div>

     <div class="option-row">
        <div class="export option-box" v-on:click="initImportExport">
        <div>
          <img :src="require('../assets/export.png')" class="content-icon">
        </div>

        <div>
          <h6>Cold Storage</h6>
        </div>
      </div>

      <div class="send option-box" v-on:click="initSend">
        <div>
          <img :src="require('../assets/send.png')" class="content-icon">
        </div>

        <div>
          <h6>Send</h6>
        </div>
      </div>

      <div class="receive option-box" v-on:click="initDeposit">
        <div>
          <img :src="require('../assets/receive.png')" class="content-icon">
        </div>

        <div>
          <h6>Receive</h6>
        </div>
      </div>

      <div class="history option-box" v-on:click="initHistory">
        <div>
          <img :src="require('../assets/history.png')" class="content-icon">
        </div>

        <div>
          <h6>History</h6>
        </div>
      </div>
     </div>



    <!--  <div class="history option-box" v-on:click="initRedeem">
       <div>
         <img :src="require('../assets/verum.png')" class="content-icon">
       </div>

       <div>
         <h6>Verum Coin</h6>
       </div>
     </div> -->


   </div>
   


   <div class="transaction-content"> 
         <div class="history-header"> 
           <span> RECENT TRANSACTIONS </span>
         </div>
         
         <div class="history-content">
           <table>
             <tr>
               <th>TIMESTAMP</th>
               <th>HASH</th>
               <th>INPUT</th>
               <th>OUTPUT</th>
               <th>AMOUNT</th> 
               <th>CONFIRMATIONS</th>
               <th>STATUS</th>
             </tr>
             <tr v-for="h in init.fund.history" :key="h.hash">
               <td >{{ formatDate(h.input.timeStamp) }}</td>
               <td class="th-orange"> <div class="hash"><a :href="'https://test-explorer-cxt.coexstar.com/#/transaction/' + h.hash" target="_blank"> {{ h.hash }}</a> </div> </td>
               <td class="th-orange"> {{ h.senderWallet != null ? h.senderWallet.publicKey : "Newly Generated CXT" }}  </td>
               <td class="th-orange">{{ h.recipient }}</td>
               <td> CXT {{ formatAmount(h.amount) }} </td> 
               <td class="th-orange">{{ h.confirmations }}</td>
               <td v-if="h.confirmations >= 2">COMPLETED</td>
               <td v-else>PENDING</td>
             </tr>
           
           </table>
       
         </div> 
   </div>
  </div>

  <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state.mainState == 'wallet' && state.subState == 'token'">
    <b-card style="width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('token')">
      <v-icon name="plus" scale="3" class="plus my-auto"/>
    </b-card>
    <AssetCard v-for="asset in assetInfo" :key="asset.id" v-if="!asset.nft && asset.active" :asset="asset" :userAddress="init.address.addressValue" @view="viewCard(asset.symbol, asset.nft)" @send="sendAsset(asset)"/>
  </div>

  <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state.mainState == 'wallet' && state.subState == 'nft'">
    <b-card style="width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('nft')">
      <v-icon name="plus" scale="3" class="plus my-auto"/>
    </b-card>
    <AssetCard v-for="asset in assetInfo" :key="asset.id" v-if="asset.nft && asset.active" :asset="asset" :userAddress="init.address.addressValue" @view="viewCard(asset.symbol, asset.nft)" @send="sendAsset(asset)"/>
  </div>
    <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state.mainState == 'subwallet' && state.assetState == 'token'">
    <SubCard v-for="asset in subwalletInfo" :key="asset.id" v-if="!asset.nft && asset.symbol != 'CXT'" :asset="asset" @view="viewSubCard(asset.symbol, asset.nft)" @send="sendSubAsset(asset)"/>
  </div>
  <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state.mainState == 'subwallet' && state.assetState == 'nft'">
    <SubCard v-for="asset in subwalletInfo" :key="asset.id" v-if="asset.nft && asset.symbol != 'CXT'" :asset="asset" @view="viewSubCard(asset.symbol, asset.nft)" @send="sendSubAsset(asset)"/>
  </div>
  <!-- <div class="d-flex justify-content-between container-fluid" style="position:fixed;bottom:15%;" v-if="showChat">
    <div></div>
    <div style="right:0;">
      <ChatModal @close="toggleChat"/>
    </div>
  </div>
  <div class="d-flex justify-content-between container-fluid">
    <div></div>
    <div>
      <img src="../assets/fab-01.png" alt="" v-if="!showChat" style="width:4rem;align-self: end;" @click="toggleChat">
      <img src="../assets/fab-02.png" alt="" v-if="showChat" style="width:4rem;align-self: end;" @click="toggleChat">
    </div>
  </div> -->
    <Send />
    <SendAsset :info="selectedAsset"/>
    <SendSubAsset :info="selectedAsset"/>
    <Deposit />
    <ExportImportFund />
    <Redeem />
    <PrivateKey />
    <ChangePin />
    <ConfirmLogout />
    <TokenContract v-if="ifAddingAsset" @close="closeContract" :isNft="isNft"/>
    <ViewCard v-if="showAsset" @close="closeAsset" :info="selectedAsset"/>
    <GeneratePrivateKey :state="generateState"/>
    <ImportPrivateKey />
    <Chat />
  </div>
</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral' 
  import Send from '@/components/modal/Send.vue'
  import Deposit from '@/components/modal/Deposit.vue'
  import ExportImportFund from '@/components/modal/ExportImportFund.vue'
  import Redeem from '@/components/modal/Redeem.vue'
  import PrivateKey from '@/components/modal/PINPrivateKey.vue'
  import ChangePin from '@/components/modal/ChangePin.vue'
  import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
  import TokenContract from '@/components/modal/TokenContract.vue'
  import ViewCard from '@/components/modal/ViewCard.vue'
  import AssetCard from '@/components/card/AssetCard.vue'
  import SubCard from '@/components/card/SubWalletCard.vue'
  import SendAsset from '@/components/modal/SendMintedAsset.vue'
  import SendSubAsset from '@/components/modal/SendSubAsset.vue'
  import ChatModal from '@/components/modal/ChatModal.vue'
  import ImportPrivateKey from '@/components/modal/ImportPrivateKey.vue'
  import GeneratePrivateKey from '@/components/modal/GeneratePrivateKey.vue'
  import Pusher from 'pusher-js' // import Pusher
  import Chat from '@/components/chat/Chat.vue'

  export default {
    name: 'Dashboard',
    components: {
      Send,
      SendAsset,
      SendSubAsset,
      Deposit,
      ExportImportFund,
      ImportPrivateKey,
      GeneratePrivateKey,
      Redeem,
      PrivateKey,
      ChangePin,
      ConfirmLogout,
      AssetCard,
      SubCard,
      TokenContract,
      ViewCard,
      ChatModal,
      Chat
    },
    data: function() {
      return  {
        showLoading: true, 
        showAsset: false,
        status: "UNCONFIRMED",
        symbol: "",
        assetInfo: {},
        subwalletInfo: {},
        state: {
          mainState: 'wallet',
          subState: 'wallet',
          assetState: 'nft'
        },
        noAsset: true,
        ifAddingAsset: false,
        isNft: false,
        selectedAsset: {},
        showChat: false,
        generateState: "",
        windowWidth: window.innerWidth
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
      changeState(name, type) {
        console.log('..')
        if(type == 'main') {
          this.state.mainState = name
        } else if(type == 'sub') {
          this.state.subState = name
        } else if (type == 'asset') {
          this.state.assetState = name
        }

        console.log(name + ',' + type)
      },

      copyAddress() {
        let copyText = document.getElementById("walletAddress");
          copyText.select();
          copyText.setSelectionRange(0, 99999); 

          document.execCommand("copy");
      },

      initContract: function(type) {
        this.ifAddingAsset = true
        if(type == 'token') {
          this.isNft = false
        } else if(type == 'nft') {
          this.isNft = true
        }
        console.log(this.isNft)
      },

      toggleChat() {
        this.showChat = !this.showChat
      },

      closeSelect() {
        this.noAsset = false
      },

      closeContract() {
        this.ifAddingAsset = false
        this.noAsset = false
      },

      viewCard(selected, ifNft) {
        if(ifNft == false) {
          this.$router.push({ name: 'AssetDashboard', params: {token: selected}})
        } else if (ifNft == true) {
          this.$router.push({ name: 'NftDashboard', params: {nft: selected}})
        } else {
          console.log(ifNft)
          console.log(selected)
        }
      },

      viewSubCard(selected, ifNft) {
        if(ifNft == false) {
          this.$router.push({ name: 'SubAssetDashboard', params: {token: selected}})
        } else if (ifNft == true) {
          this.$router.push({ name: 'SubNftDashboard', params: {nft: selected}})
        } else {
          console.log(ifNft)
          console.log(selected)
        }
      },

      closeAsset() {
        this.showAsset = false
      },


      initPin: function() {
        this.$bvModal.show('change-pin-modal'); 
        console.log('..')
      },

      initImportKey: function(){
        this.$bvModal.show('import-key-modal'); 
      },

      initGenerateKey: function(isShown){
        this.generateState = isShown
        this.$bvModal.show('generate-key-modal');
        setTimeout(()=>{
          this.generateState = ""
        },5000);
      },

      initAsset: function() {
        this.$router.push('/asset')
      },

       initPrivateKey: function(){
        this.init.viewPrivKey.state = 'pin';
        this.$bvModal.show('pin-key-modal'); 
      },
       initRedeem: function(){
        this.$bvModal.show('redeem-modal'); 
      },
      initHistory: function(){
        window.location.href = "/#/history";
      },
      initSubHistory: function(){
        window.location.href = "/#/subwallet/history";
      },
      initImportExport: function(){ 
        this.$bvModal.show('export-import-modal'); 
      },

      initSend: function() {
        this.$bvModal.show('send-modal'); 
      },

      sendAsset: function(info) {
        this.$bvModal.show('send-asset-modal'); 
        this.selectedAsset = info
      },

      sendSubAsset: function(info) {
        this.$bvModal.show('send-subasset-modal'); 
        this.selectedAsset = info
      },

       initDeposit: function() {
        this.$bvModal.show('deposit-modal'); 
      },
      formatDate: function(time){
        return new Date(time).toLocaleString();
      },
      formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

      openSmartContract() {
        let routeData = this.$router.resolve({name: 'SelectOption'});
        window.open(routeData.href, '_blank');
      },

      openRouter() {
        if(this.assetInfo.icon != null) {
          this.toCoin('AssetDashboard')
        } else if (this.assetInfo.media != null) {
          this.toCoin('NftDashboard')
          console.log('nft')
        } 
      },

      toCoin(name) {
        this.$router.push({name: name})
      },

      onResize() {
        this.windowWidth = window.innerWidth
      },

      getAssetInfo() {
          const options = {
                method: 'POST',
                url: '/cx/smartcontract',
                };
                this.axios(options)
                    .then((response) => {
                      if(response.data.length > 0) {
                          
                          this.noAsset = false 
                          this.assetInfo = response.data;
                          // setTimeout(()=>{
                          //   console.log('yes1111')
                          //   console.log(this.subwalletInfo)
                          //   //this.assetInfo = response.data

                          //   this.assetInfo = [];

                          //   response.data.forEach((el,i) => {
                          //     this.subwalletInfo.forEach((el2,i2) => {
                          //       if(el.symbol == el2.symbol & el2.balance >  0) {
                          //         this.assetInfo.push(el);
                          //       }
                          //     })
                          //   })

                            

                          // },500)
                      } else {
                        console.log('no')
                        // this.initSelect()
                      }
                    })
                    .catch((response) => {
                      console.log(response.response)
                      // if(response.assetKey != "" && response.assetName == "") {
                      //   this.showSteps.one = false
                      //   this.showCheck.one = true
                      //   this.showSteps.two = false
                      //   this.showSteps.three = true
                      //   this.showCheck.two = true
                      //   this.isDisabled.three = false
                      // }
                    });
          },

          getSubwalletInfo() {
          const options = {
                method: 'GET',
                url: '/pub/wallets/' + this.init.address.addressValue,
                };
                this.axios(options)
                    .then((response) => {
                      console.log(response.data)
                      if(response.data.length > 0) {
                          console.log('yes')
                          this.subwalletInfo = response.data
                          console.log(this.subwalletInfo)
                      } else {
                        console.log('no')
                        // this.initSelect()
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                      // if(response.assetKey != "" && response.assetName == "") {
                      //   this.showSteps.one = false
                      //   this.showCheck.one = true
                      //   this.showSteps.two = false
                      //   this.showSteps.three = true
                      //   this.showCheck.two = true
                      //   this.isDisabled.three = false
                      // }
                    });
          },

      getAddress: function(){
         const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/address/CXT',
          };

          this.axios(options)
            .then((response) => {
                this.init.address = response.data;
                //console.log(this.init.address)
                this.getFund();
                this.getSubwalletInfo();
              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },

      getFund: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
            url: '/cxt-api/address/CXT/' + this.init.address.addressValue,
          };

          this.axios(options)
            .then((response) => {
               
               this.init.fund = response.data;
               this.init.fund.history.forEach((value, index) => {
                  if(value.confirmations >= 2) {
                    console.log(value.confirmations)
                    this.status = "COMPLETED"
                  } else {
                    console.log('error')
                    this.status = "PENDING"
                  }

               });

              // console.log(this.init.fund.balance);

              this.init.fund.history.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
            });

              this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },


      getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
               this.init.profile = response.data;
 


                
              })
      },
      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => { 
            this.getProfile();
              setTimeout( () => {
                this.showLoading = false;
              },1000)
            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      logout: function(){
            this.$bvModal.show('logout-modal'); 
      },

      subscribe () {
        let pusher = new Pusher('609acb09a8614556b686', { cluster: 'ap3' })
        pusher.subscribe('blx')
        pusher.bind('balance', data => {
          this.getFund();
        })
      },
    },

    beforeMount(){
     this.checkSession();
      this.getAddress();
      this.getAssetInfo();
      console.log(this.init.fund.history.confirmations)
    },

    mounted() {
      let socketScript = document.createElement('script')
        socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
        document.head.appendChild(socketScript)
      console.log(this.init.fund.history.confirmations)

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },
    

     created () {
      // ...
      this.subscribe()
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  #loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  color: #FFF;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
      background: #141414;
}
#loading .content {
  display: inline-block;
  text-align: center;
}
#loading .content img {
  display: block;
  margin-bottom: 0.5em;
}
#loading .content .icon {
  font-size: 3em;
}

.fa-icon { 
    color: #FFF;
    fill: #fff;
}
  .z-index-2 {
    z-index: 2;
  }
  .transaction-content {
    padding: 30px;
    margin-top: 30px;

  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #333;
  }
   .hash {
    white-space: nowrap !important;
    width: 20em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  td, th {
   
    text-align: left;
    padding: 1em;
    font-size: 0.75em;
  }

  td {  
    background: #1d2132;
    color: #FFF;
    border-bottom: 1px solid #FFF;
    padding: 1.5em;
  
  }

  th {
     
    background: #131625;
    color: #FFF;
  }

  th:first-child { 
    border-top-left-radius: 6px;
  }
  th:last-child { 
    border-top-right-radius: 6px;
  }

  .history-content {
    background: #FFF; 
      border-radius: 6px;
        overflow-x:auto;
  }
    


  .history-container {  
      padding: 1.5em;
      border-radius: 6px;
      flex-direction: column;
      position: relative;
      background: #FFF;
      padding-top: 5em;
      color: #000;
      background-color: #b48b41;
      background-image: linear-gradient(#b48b41, #b48b41);
      width: 100%;
      margin: 0 2em;
  }
  
  .history-header {  
    color: #FFF;
    display: flex;
    justify-content: left;
    align-items: center;;
  }
.logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    margin-top: 2em;
}
 .logo img {
      width: 75px;  
    }

    .add-btn .fa-icon {
      fill: #000;
    }

    .option-row {
      display:flex;
      flex-wrap:wrap;
      justify-content:space-around;
      width:800px;
    }
    
  


  .option-box {

    background: #1d2132;
    border: 1px solid #8c5b13;
    color: #FFF;
    border-radius: 6px; 
    min-height: 15em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3em;
    cursor: pointer;
    margin:15px 0px;
  }
  .option-box h6 {
      font-size: 0.75em;
    }
  
 .content-icon {
      width: 75px;
    }
  .balance {
    font-size: 0.8em;
    position: relative;
    width: 400px; 
    background-image: url(../assets/wallet-bg.png);
    box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
-webkit-box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
-moz-box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
    padding: 1em;
    color: #FFF;
    border-radius: 6px;
    min-height: 11em;
    display: flex;
    flex-direction: column;
    justify-content: center;
        background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin:auto 0;
  }
    .balance .bal-bg {
          position: absolute;
        z-index: 1;
        width: 20em;
        left: 2em;
        top: 2em;
    }

    .balance .bal-bg a {
      color: #8c5b13 !important;
    }
  

  .header { 
    
        background: #202538;
    background: linear-gradient(180deg, #202538, #10121c);
    padding: 2em;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: #FFF;
    flex-wrap:wrap;
  }
  .logout {
    color: #FFF;
  }

  .content {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 1em;
    padding: 0 30px;
  }
  .add-btn {
    background: transparent;
    padding: 0 !important;
    margin: 0!important;
    text-decoration: none !important;
  }
  .add-btn .plus {
    color: black;
  }
  .add-btn:hover {
    background: transparent;
    color: black;
  }
  .add-btn:focus {
    background: transparent !important;
    color: black;
  }

  .bloxchain {
    width:10%;
  }

  .cancel {
	  color:orange;
	  background:none;
	  border:none;
	  font-family:'PoppinsMedium', sans-serif;
	  width:15%;
    outline:none;
  }

  .cancel:focus {
    box-shadow:1px 1px orange;
  }

  .select {
	  color:orange;
	  background:none;
	  border-color:orange;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
  }

  .active {
    border:none;
	  border-bottom:1px orange solid;
	  font-family:'PoppinsMedium', sans-serif;
	  width:15%;
  }


  .card-body {
    border-color:gray;
    border-width:2px;
    border-style:dashed;
  }

  .plus {
    fill:gray;
  }



  @media(min-width: 1400px) {
    
  }
  
  @media(max-width: 800px) {
    .balance {
      box-shadow:none;
    }

    .logout {
      display:none;
    }
   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
 
  }
  /*med*/
  @media(max-width: 63.9375em) {

    
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(min-width: 768px) {
    .content {
      padding: 30px;
    }

    .transaction-content {
      padding: 48px;
    }

  }

</style>