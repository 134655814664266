import { render, staticRenderFns } from "./NftDashboard.vue?vue&type=template&id=8ea74440&scoped=true&"
import script from "./NftDashboard.vue?vue&type=script&lang=js&"
export * from "./NftDashboard.vue?vue&type=script&lang=js&"
import style0 from "./NftDashboard.vue?vue&type=style&index=0&id=8ea74440&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ea74440",
  null
  
)

export default component.exports