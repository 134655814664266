<template>
	<b-modal id="import-key-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()"/>
			</div>
			<h5>
				IMPORT PRIVATE KEY
			</h5> 
			<div></div>

    </template>


		<div v-if="ei.state == 'alert'"> 
			<div class="text-center">
        <h4>
          <strong>IMPORTANT</strong> - Please be sure to have your
          Private key before migrating
          a new one.
        </h4>
        <button class="btn btn-main" @click="nextStep">CONFIRM</button>
      </div>
		</div>
		<div v-if="ei.state == 'pin'"> 
			<form  v-on:submit.prevent="verifyPin" class="text-center">
				<p class="text-center">Enter your PIN:</p>
				<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
				<div class="d-flex justify-content-center">
					<div class="pin-wrapper" >
						<div>
						  <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
						</div>
			  
						<div>
						  <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
						</div>
			  
						<div>
						  <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
						</div>
			  
						<div>
						  <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
						</div>

						<div>
							<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
						  </div>
				
						  <div>
							<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
						  </div>
			  
					  </div> 
				</div>
		
				<div class="form-group ">
					<button type="submit" class="btn btn-main">SUBMIT</button>
				</div>
	
			</form>
		</div>
		<div v-if="ei.state == 'otp'"> 
			<form  v-on:submit.prevent="verifyOtp">
		
				<p class="text-center">Enter the one-time PIN you received:</p>
				<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
				<div class="d-flex justify-content-center">
					<div class="otp-wrapper" >
					<div>
						<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					</div>
			
					<div>
						<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					</div>
					</div>
				</div>
        <div class="text-center">
          <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA? <span style="text-decoration: underline;">Try Again</span></p>
          <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
				</div>
		
				<div class="form-group ">
					<button type="submit" class="btn btn-main">SUBMIT</button>
				</div>
			</form>
		</div>
    <div v-if="ei.state == 'key'"> 
      <form>
				<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
				<div class="form-container">
					<label for="amount">Enter your Private Key:</label>
					<input type="text" class="form-control" v-model="privateKey">
				</div>
			</form>
      <div class="d-flex justify-content-center text-center">
        <button class="btn btn-main" @click="importKey">CONFIRM</button>
      </div>
		</div>
    <div v-if="ei.state == 'success'"> 
			<div class="text-center">
        <h4>
          Import Successful.
        </h4>
        <button class="btn btn-main" @click="nextStep">CONFIRM</button>
      </div>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
import PrivateKey from '@/components/modal/PrivateKey.vue'
export default {
  name: 'ExportImportFund',
  components: {
	  PrivateKey
  },
  data: function() {
      return  {
      	ei: {
      		state: 'alert'
      	},
      	showError: false,
        systemMsg: "",
        seed: {
      		one: "",
      		two: "",
      		three: "",
      		four: "",
      		five: "",
      	},
        privateKey: "",
        pinArr: [],
        otpArr: [],
        ifResent: true,
        timerInSeconds: 60,

      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {

   		close: function(){
   			this.$bvModal.hide('import-key-modal'); 
         this.pinArr = [],
          this.otpArr = [],
          this.systemMsg = ""
          this.showError = false
          this.ifResent = true
          this.timerInSeconds = 60
          this.privateKey = ""
   		},

       nextStep() {
         if(this.ei.state == 'alert') {
          this.ei.state = 'pin'
         } 
         else if (this.ei.state == 'success') {
           window.location.reload()
         }
       },

       goBack() {
        this.ei.state = 'seed'
       },

       importKey() {
          const params = {
            privateKey: this.privateKey,
            symbol: "CXT",
            sp1: this.seed.one,
            sp2: this.seed.two,
            sp3: this.seed.three,
            sp4: this.seed.four,
            sp5: this.seed.five,
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
        

          const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data,
          url: '/cx/private/import',
          };
          this.axios(options)
            .then((response) => {
						  this.ei.state = 'success'
              this.clearError()
            })
            .catch((error) => {
            // Error code goes here
						this.showError = true
            this.systemMsg = error.response.data.msgText
            if(error.response.data.msgText == "Field validation errors") {
              this.systemMsg = error.response.data.fieldErrors[0].defaultMessage
              console.log('...')
            } else if(error.response.data.msgText == "Authentication Required") {
              this.$router.push('/login')
            }
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
       },

       resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {
						          this.ei.state = 'otp'
                      console.log('...')
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
                      this.showError = true
                      this.systemMsg = error.response.data.msgText
                      console.log(error.response.data.msgText)
                      setTimeout(()=>{
                      this.clearError()
                    },10000);
                  });

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                      this.ei.state = 'key'
                      this.clearError()
						          
                      // this.$bvModal.hide('pin-key-modal'); 
                    })
                    .catch((error) => {
                        // Error code goes here
                      this.showError = true
                      this.otpArr = []
                      this.systemMsg = error.response.data.msgText
                      console.log(error.response.data.msgText)
                      setTimeout(()=>{
                      this.clearError()
                    },10000);
				});
        },

        isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },

        otpBack: function(event, currrentBox) {
		if(this.ei.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.ei.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },
   		

	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #b48b41;
          border-bottom: 1px solid #b48b41;
        }
      }
 
    }

	.pin-wrapper {
      display: flex;
      width: 66%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
              padding-bottom:7.5px;
      }
    }
	}
	.otp-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
	}

	.btn-upload {
		border-color:lightgray;
		border-width:1px;
		color:gray;
		width:100%;
	}
	
</style>
